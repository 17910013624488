import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import axios, { AxiosResponse } from 'axios';
import { environment } from 'src/environments/environment';
import { polling, Toasts, ToastType } from 'src/helperFunctions';
import { setAuthCode, setAuthDone, setGID, setUserCompany } from 'src/state/actions';
import { returnAuthCode, returnAuthDone, returnDuration, returnEncodedRedirectUri, returnRedirectUri, returnRenewTokenShow, returnText } from 'src/state/selectors';
import { SiToastService } from '@simpl/siemens-brand-ng/toast';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  constructor(
    private store: Store,
    private router: Router,
    private toastService: SiToastService
  ) {}
  
  // page rendering variables
  trackByIndex = (index: number) => index;
  loading = true;
  deactivated = true;
  // Auth variables
  authDone = false;
  encodedRedirectUri = '';
  pollingDuration = 0;
  renewShow = true; // TODO: hide/show renewToken

  async ngOnInit(): Promise<void> {
    /**
     * NECESSARY STORE VARIABLES
     * checking if the user is already authenticated
     * fetching the encoded redirect uri
     * fetching the polling duration
     */
    this.store
      .select(returnAuthDone)
      .subscribe((val) => {
        this.authDone = val;
      })
      .unsubscribe();

    this.store
      .select(returnEncodedRedirectUri)
      .subscribe((eru) => {
        this.encodedRedirectUri = eru;
      })
      .unsubscribe();

    this.store
      .select(returnDuration)
      .subscribe((duration) => {
        this.pollingDuration = duration;
      })
      .unsubscribe();
    
    // TODO: hide/show renewToken
    this.store
      .select(returnRenewTokenShow)
      .subscribe((val) => {
        this.renewShow = val;
      })
      .unsubscribe(); 

    // Grabbing the auth code from the URL, since it drops the '/authorize/callback'
    const urlParams: URLSearchParams = new URLSearchParams( window.location.search );

    /**
     * ROUTING
     * Checking if it's the redirect or if authenticated:
     *    redirect → parsing of auth code etc
     *    unauthenticated → redirect to fetch the Auth code,
     *    authenticated → redirecting to the main page
     */
    if (urlParams.get('code') === null) {
      this.redirection(this.authDone, this.encodedRedirectUri);
    } else {
      // @ts-ignore: null check done line above
      this.contactTokenRA(urlParams.get('code'));
    }
  }


  /**
   * parsing the auth code from the url,
   * then passes auth code to TokenRA backend,
   *    receive TokenRA login token
   *    receive VSC token array
   */
  contactTokenRA(code: string) {
    this.store.dispatch(setAuthCode({ value: code }));
    this.backendLogin();
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store
      .select(returnText(id))
      .subscribe((val) => {
        msg = val;
      })
      .unsubscribe();
    return msg;
  }

  /**
   * fetches a language specific message from the store
   * trying to fix a reference error
   * @param id
   * @returns msg
   */
  storeTextFetch(id: string, store: Store = this.store): string {
    let msg = '';
    store
      .select(returnText(id))
      .subscribe((val) => {
        msg = val;
      })
      .unsubscribe();
    return msg;
  }

  /**
   * Authenticated:
   *    start polling
   *    set language selection
   *    redirect to main page
   * Unauthenticated:
   *    Redirecting to fetch the Auth code
   */
  async redirection(authDone: boolean, encodedRedirectUri: string) {
    if (authDone) {
      polling(
        this.store,
        this.pollingDuration,
        this.toastService,
        this.router,
        this.storeTextFetch
      );
      this.router.navigate(['main']);
    } else {
      // Redirect to Identity Provider
      window.location.assign(
        environment.NG_APP_IDP_URL +
          '&redirect_uri=' +
          encodedRedirectUri +
          encodeURI('/index/authorize/callback') +
          '&state=1'
      );
    }
  }

  /**
   * POST request to the backend server /login
   * @returns a backend id_token
   * switches the auth_done parameter
   * routes to welcome page
   **/
  backendLogin = async () => {
    const backendLogin = this.store
      .select(returnAuthCode)
      .subscribe(async (eac) => {
      const backendLoginInner = this.store
          .select(returnRedirectUri)
          .subscribe(async (eru) => {
        const encodedEac = encodeURIComponent(eac);
        const encodedEru = encodeURIComponent( `${eru}/index/authorize/callback` );

            await axios({
              method: 'post',
              url: `${environment.NG_APP_BACKEND}/login`,
              data: `auth_code=${encodedEac}&redirect_uri=${encodedEru}`,
              withCredentials: true,
            })
              .then((res) => {
                backendLogin.unsubscribe();
                backendLoginInner.unsubscribe();
                if (this.hasEmailAddress(res)){
                  polling(
                    this.store,
                    this.pollingDuration,
                    this.toastService,
                    this.router,
                    this.storeTextFetch
                  );
                }
                this.store.dispatch(setAuthDone({ value: true }));
                this.store.dispatch(setGID({ value: res.data.gid }));
                this.store.dispatch(setUserCompany({ value: res.data.company }));
                return res;
              })
              .then((res) => {
                this.router.navigate(['main']);
                if (!this.hasEmailAddress(res)) {
                  Toasts(
                    this.toastService,
                    ToastType.ERROR,
                    this.textFetch('No_Email'),
                    5000
                  );
                }
              })
              .catch((error) => {
                backendLogin.unsubscribe();
                backendLoginInner.unsubscribe();
                console.error('Backend login failed:', JSON.stringify(error));
                console.error(JSON.stringify(error.request));
                if (error.response?.status === 403) {
                  Toasts(
                    this.toastService,
                    ToastType.ERROR,
                    this.textFetch('Auth_Error'),
                    5000
                  );
                } else {
                  Toasts(
                    this.toastService,
                    ToastType.ERROR,
                    this.textFetch('Proxy_Error'),
                    5000
                  );
                }
              });
          });
      });
  };

/** 
 * The `hasEmailAddress` function is a helper function that takes in an AxiosResponse object 
 * and checks if the response data contains a non-null and non-empty email property. 
 * If the user has no email address registered, then display an error message and block page functions!
 */ 
  hasEmailAddress = (res: AxiosResponse<any, any>): boolean => {
    if (res.data.email !== null && res.data.email.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
