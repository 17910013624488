// import { CHINESE as CN } from 'src/languages/chinese';
import { createReducer, on } from '@ngrx/store';
import { DEUTSCH as DE } from 'src/languages/deutsch';
import { ENGLISH as EN } from 'src/languages/english';
// import { ESPANOL as ES } from 'src/languages/espagnol';
// import { FRANCAIS as FR } from 'src/languages/francais';
import { OrderState } from 'src/helperFunctions';
// import { PORTUGUES as PT } from 'src/languages/portugues';
import { StoreState } from 'src/models/state.model';
import * as Actions from './actions';

export const State: StoreState = {
  // AUTH AND TOKEN
  authDone: false,
  authCode: '',
  GID: '',
  userCompany: '',
  tokenRequests: [],
  token: '',
  PIN: 0,
  duration: 10000, // polling interval in ms
  // STATES
  orderState: OrderState.PROCESSING, // OrderState.ORDER, OrderState.ACTIVATE, OrderState.CANCEL or OrderState.PROCESSING
  orderStep: 1, // 1, 2
  activateStep: 1, // 1, 2
  renewStep: 1, // 1, 2
  cancelStep: 1, // 1, 2
  compromisedStep: 1, // 1, 2
  language: EN, // EN, DE, FR, ES, PT, CN
  language_icon: 'EN', // '', EN, DE, FR, ES, PT, CN
  redirectDuration: { // in seconds
    order: 15,
    activate: 15,
    renew: 15,
    cancel: 10,
    compromised: 10
  },
  pollingTimeOut: 0,
  pollingTimeOutDuration: 10 * 60000, // minutes multiplied by milliseconds
  pageExpired: false,
  deactivate: false,
  // renewTokenShow: false // TODO: hide/show renewToken
  renewTokenShow: true // TODO: hide/show renewToken
};

export const Reducer = createReducer(
  State,

  // setting login value
  on(Actions.setAuthDone, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.authDone = value;
    return storeClone;
  }),
  // setting Start state
  on(Actions.setOrderState, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.orderState = value;
    return storeClone;
  }),
  // setting Order step
  on(Actions.setOrderStep, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.orderStep = value;
    return storeClone;
  }),
  // setting Activate step
  on(Actions.setActivateStep, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.activateStep = value;
    return storeClone;
  }),
  // setting Deactivate step
  on(Actions.setCompromisedStep, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.compromisedStep = value;
    return storeClone;
  }),
  // setting Cancelation step
  on(Actions.setCancelStep, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.cancelStep = value;
    return storeClone;
  }),
  // setting Compromised step
  on(Actions.setCompromisedStep, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.compromisedStep = value;
    return storeClone;
  }),
  // setting Language
  on(Actions.setLanguage, (state, { setting }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    switch (setting) {
      case 'EN': // English
        storeClone.language = EN;
        break;
      case 'DE': // German
        storeClone.language = DE;
        break;
      // case 'FR': // French
      //   storeClone.language = { ...FR };
      //   break;
      // case 'ES': // Spanish
      //   storeClone.language = { ...ES };
      //   break;
      // case 'PT': // Portuguese
      //   storeClone.language = { ...PT };
      //   break;
      // case 'CN': // Chinese
      //   storeClone.language = { ...CN };
      //   break;
      default:
        storeClone.language = EN;
        alert('Unrecognized Language!');
    }
    return storeClone;
  }),
  // setting Language Icon
  on(Actions.setLanguageIcon, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.language_icon = value;
    return storeClone;
  }),
  // setting polling Duration
  on(Actions.setDuration, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.duration = value;
    return storeClone;
  }),
  // setting Auth Code
  on(Actions.setAuthCode, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.authCode = value;
    return storeClone;
  }),
  // setting Token
  on(Actions.setToken, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.token = value;
    return storeClone;
  }),
  // setting Polling TimeOut
  on(Actions.setPollingTimeOut, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.pollingTimeOut = value;
    return storeClone;
  }),
  // setting Polling TimeOut Duration
  on(Actions.setPollingTimeOutDuration, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.pollingTimeOutDuration = value;
    return storeClone;
  }),
  // setting Page Expired
  on(Actions.setPageExpired, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.pageExpired = value;
    return storeClone;
  }),
  // setting GID
  on(Actions.setGID, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.GID = value;
    return storeClone;
  }),
  // setting userCompany
  on(Actions.setUserCompany, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.userCompany = value;
    return storeClone;
  }),
  // setting Deactivate
  on(Actions.setDeactivate, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.deactivate = value;
    return storeClone;
  }),
  // setting Renew step
  on(Actions.setRenewStep, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.renewStep = value;
    return storeClone;
  }),
  // TODO: hide/show renewToken
  // setting Renew Token Visibility
  on(Actions.setRenewTokenShow, (state, { value }) => {
    const storeClone: StoreState = JSON.parse(JSON.stringify(state));
    storeClone.renewTokenShow = value;
    return storeClone;
  }),

);
