import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { returnCompromisedStep, returnLanguage, returnRedirectDuration, returnText, returnToken } from 'src/state/selectors';
import { MainComponent } from 'src/app/pages/main/main.component';
import { setCompromisedStep, setOrderState } from 'src/state/actions';
import { countdown, extendPollingTimeOut, OrderState, Toasts, ToastType, tokenRequests } from 'src/helperFunctions';
import { SiToastService } from '@simpl/siemens-brand-ng/toast';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-compromised',
  templateUrl: './compromised.component.html',
  styleUrls: ['./compromised.component.scss'],
})
export class CompromisedComponent implements OnInit {
  constructor(
    private store: Store,
    private main: MainComponent,
    private toastService: SiToastService
  ) {}  

  // makes the OrderState enum available in the template
  OrderState = OrderState;
  compromisedStep = 1;
  confirmation = false;
  tabs = [''];
  time = {
    countDownLength: 1,
    count: 1,
  };

  ngOnInit(): void {
    this.store.select(returnCompromisedStep).subscribe((val) => {
      this.compromisedStep = val;
    });
    this.store.select(returnLanguage).subscribe((lang) => {
      this.tabs = lang.Compromised_tabs;
    });
    const redirectDuration = this.store
      .select(returnRedirectDuration('compromised'))
      .subscribe((val) => {
        this.time.countDownLength = val;
      });
    redirectDuration.unsubscribe();
  }

  compromised() {
    extendPollingTimeOut(this.store);
    this.compromisedToken(this.toastService, this.storeTextFetch);
    this.store.dispatch(setOrderState({ value: OrderState.PROCESSING }));
    this.store.dispatch(setCompromisedStep({ value: 2 }));
    countdown(this.time, this.redirect.bind(this));
  }

  redirect(): void {
    this.main.selectTab(0);
    this.confirmation = !this.confirmation;
    this.store.dispatch(setCompromisedStep({ value: 1 }));
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store
      .select(returnText(id))
      .subscribe((val) => {
        msg = val;
      })
      .unsubscribe();
    return msg;
  }

  /**
   * fetches a language specific message from the store
   * Store issue fix
   * @param id
   * @returns msg
   */
  storeTextFetch(id: string, store: Store): string {
    let msg = '';
    store
      .select(returnText(id))
      .subscribe((val) => {
        msg = val;
      })
      .unsubscribe();
    return msg;
  }

  /**
   * The `compromisedToken` function is an asynchronous function that cancels a token AND ENCRYPTION CERTIFICATE 
   * by making a DELETE request to the backend API and handles the success and error cases by displaying toast messages.
   * @param {SiToastService} toastService - The `toastService` parameter is an instance of a service
   * that is responsible for displaying toast notifications to the user. It is used to show success or
   * error messages when certain actions are performed.
   * @param storeTextFetch - A function that takes an ID and a store object as parameters and returns a
   * string.
   */
  async compromisedToken(
    toastService: SiToastService,
    storeTextFetch: (id: string, store: Store) => string
  ): Promise<void> {
    const _compromisedToken = this.store
      .select(returnToken)
      .subscribe(async (token) => {
        await axios({
          method: 'delete',
          url: `${environment.NG_APP_BACKEND}/token/${token}?revokeEnc`,
          withCredentials: true,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
          .then(() => {
            _compromisedToken.unsubscribe();
            tokenRequests(this.store, toastService, storeTextFetch);
            Toasts(
              toastService,
              ToastType.SUCCESS,
              storeTextFetch('Cancel_confirmed', this.store),
              5000
            );
          })
          .catch((error) => {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            Toasts(
              toastService,
              ToastType.ERROR,
              storeTextFetch('Token_Process_Error', this.store),
              5000
            );
            console.error(`Cancel Token Error: ${JSON.stringify(error)}`);
          });
      });
  }
}
