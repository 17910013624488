<section>
  <!--style="overflow-x: hidden; overflow-y: auto;" -->
  <div class="row">
    <!-- Graphics -->
    <div class="col-4" style="position: relative; top: 40px">
      <div class="bg-vrt-line"></div>
      <div
        class="icon-box red"
        style="top: -45px"
        [class.inactive]="compromisedStep !== 1"
      >
        <i class="siemens-warning red"></i>
        <div>{{ textFetch("Compromised_heading") }}</div>
      </div>
      <div
        class="icon-box red"
        style="top: 134px"
        [class.inactive]="compromisedStep !== 2"
      >
        <i class="siemens-success "></i>
        <div>{{ textFetch("Compromised_cancelled") }}</div>
      </div>
    </div>

    <!-- Text Fields -->
    <div class="col-8">
      <!-- Confirm Compromised Section -->
      <div *ngIf="compromisedStep === 1">
        <div style="margin: 25px 0">
          <h6>{{ textFetch("Compromised_heading") }}</h6>
        </div>
        <div style="margin: 25px 0">
          {{ textFetch("Compromised_main") }}
          <ul style="margin: 25px 0">
            <li>{{ tabs[0] }}</li>
            <li>
              {{ tabs[1] }}
              <a
                href="https://mypki.siemens.com/"
                class="fakeLink"
                target="_blank"
                style="text-decoration: underline"
              >
                {{ textFetch("Here") }}
              </a>
            </li>
            <li>
              {{ tabs[2] }}
            </li>
          </ul>
        </div>
        <div class="form-check" style="margin: 25px 0">
          <input
            class="form-check-input"
            type="checkbox"
            (change)="confirmation = !confirmation"
          />
          <label for="cb-enabled" class="form-check-label">
            {{ textFetch("Compromised_confirmation") }}
          </label>
        </div>
        <button
          type="button"
          class="btn btn-hero"
          (click)="compromised()"
          *ngIf="confirmation"
        >
          {{ textFetch("Compromised_button") }}
        </button>
      </div>
      <!-- VSC INVALIDATION PROCESSING -->
      <div *ngIf="compromisedStep === 2">
        <div style="margin: 25px 0">
          <h6>{{ textFetch("Compromised_cancelled") }}</h6>
        </div>
        <div style="margin: 25px 0">
          {{ textFetch("Compromised_main2") }}
        </div>
        <div style="margin: 25px 0">
          {{ textFetch("Redirecting") }} {{ time.count }}
          {{ textFetch("Seconds") }}
        </div>
      </div>
    </div>
  </div>
</section>
