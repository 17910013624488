<section>
  <!--style="overflow-x: hidden; overflow-y: auto;" -->
  <div class="row">
    <!-- Graphics -->
    <div class="col-4" style="position: relative; top: 40px">
      <div class="bg-vrt-line"></div>
      <div
        class="icon-box"
        style="top: -45px"
        [class.inactive]="cancelStep !== 1"
      >
        <div class="line" [class.inactive]="cancelStep !== 1"></div>
        <i
          class="siemens-shopping-cart cart-spacing"
          style="margin-top: 20px"
        ></i>
        <div>{{ textFetch("Cancel_confirm") }}</div>
      </div>
      <div
        class="icon-box"
        style="top: 134px"
        [class.inactive]="cancelStep !== 2"
      >
        <i class="siemens-success"></i>
        <div>{{ textFetch("Cancel_cancelled") }}</div>
      </div>
    </div>

    <!-- Text Fields -->
    <div class="col-8">
      <!-- Confirm Cancellation Section -->
      <div *ngIf="cancelStep === 1">
        <div style="margin: 25px 0">
          <span style="color: #00ffb9">{{ textFetch("Step") }} 1/2</span>
          <h6>{{ textFetch("Cancel_confirm") }}</h6>
        </div>
        <div style="margin: 25px 0">
          {{ textFetch("Cancel_main") }}
        </div>
        <div style="margin: 25px 0">
          {{ textFetch("Cancel_charges") }}
        </div>
        <div class="form-check" style="margin: 25px 0">
          <input
            class="form-check-input"
            type="checkbox"
            (change)="confirmation = !confirmation"
          />
          <label for="cb-enabled" class="form-check-label">
            {{ textFetch("Cancel_confirmation") }}
          </label>
        </div>
        <button
          type="button"
          class="btn btn-hero"
          (click)="cancel()"
          *ngIf="confirmation"
        >
          {{ textFetch("Cancel_button") }}
        </button>
      </div>

      <!-- Cancelled Service Section -->
      <div *ngIf="cancelStep === 2">
        <div style="margin: 25px 0">
          <span style="color: #00ffb9">{{ textFetch("Step") }} 2/2</span>
          <h6>{{ textFetch("Cancel_cancelled") }}</h6>
        </div>
        <div style="margin: 25px 0">
          {{ textFetch("Cancel_second") }}
        </div>
        <div style="margin: 25px 0">
          {{ textFetch("Redirecting") }} {{ time.count }}
          {{ textFetch("Seconds") }}
        </div>
      </div>
    </div>
  </div>
</section>
