import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SiToastService } from '@simpl/siemens-brand-ng/toast';
import axios from 'axios';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MainComponent } from 'src/app/pages/main/main.component';
import { environment } from 'src/environments/environment';
import { countdown, extendPollingTimeOut, OrderState, Toasts, ToastType, tokenRequests } from 'src/helperFunctions';
import { setActivateStep, setOrderState } from 'src/state/actions';
import { returnActivateStep, returnRedirectDuration, returnText, returnToken } from 'src/state/selectors';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
  constructor(
    private store: Store,
    private modalService: BsModalService,
    private main: MainComponent,
    private toastService: SiToastService
  ) {}

  // makes the OrderState enum available in the template
  OrderState = OrderState;
  activateStep = 1;
  control1: FormControl = new FormControl('');
  control2: FormControl = new FormControl('');
  excessive = false;
  insufficient = false;
  modalRef!: BsModalRef;
  non_match = false;
  PIN1: string[] = [];
  PIN2: string[] = [];
  submitDisabled = true;
  time = {
    countDownLength: 1,
    count: 1
  };

  ngOnInit(): void {
    this.store.select(returnActivateStep).subscribe(val => {
      this.activateStep = val;
    });
    const redirectDuration = this.store.select(returnRedirectDuration('activate')).subscribe(val => {
      this.time.countDownLength = val;
    });
    redirectDuration.unsubscribe();
  }

  confirm(PinInput: TemplateRef<any>) {
    this.modalRef = this.modalService.show(PinInput);
  }

  submit() {
    extendPollingTimeOut(this.store);
    this.activateToken(this.control1.value);
    this.modalRef.hide();
    this.store.dispatch(setActivateStep({ value: 2 }));
    this.store.dispatch(setOrderState({ value: OrderState.PROCESSING }));
    countdown(this.time, this.redirect.bind(this));
  }

  redirect(): void {
    this.main.selectTab(0);
    this.cancel();
    this.store.dispatch(setActivateStep({ value: 1 }));
  }

  async activateToken(PIN: string): Promise<void> {
    const activateToken = this.store.select(returnToken).subscribe(tokenID => {
      axios({
        method: 'put',
        url: `${environment.NG_APP_BACKEND}/token/${tokenID}`,
        withCredentials: true,
        data: `pin=${PIN}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
        .then(() => {
          activateToken.unsubscribe();
          tokenRequests(this.store, this.toastService, this.storeTextFetch);
          [this.PIN1, this.PIN2] = [[], []];
          this.control1.setValue('');
          this.control2.setValue('');
          this.submitBtnDisabled();
          Toasts(this.toastService, ToastType.SUCCESS, this.textFetch('Activation_confirmation'), 5000);
        })
        .catch(error => {
          activateToken.unsubscribe();
          [this.PIN1, this.PIN2] = [[], []];
          this.control1.setValue('');
          this.control2.setValue('');
          this.submitBtnDisabled();
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          Toasts(this.toastService, ToastType.ERROR, this.textFetch('Activation_error'), 5000);
          console.error(`Activation Error: ${ JSON.stringify(error)}`);
        });
    });
  };

  /* Clearing the input fields and closing the modal. */
  cancel = () => {
    [this.PIN1, this.PIN2] = [[], []];
    this.control1.setValue('');
    this.control2.setValue('');
    this.lengthWarning();
    this.matchWarning();
    this.submitBtnDisabled();
    this.modalRef.hide();
  };

  /**
   * The function takes in two parameters, the index of the input and the value of the input.
   * It then assigns the values to the correct inputs and checks the length of the inputs.
   * If the inputs are equal, not null, consist of only numbers and their length is 8,
   * then the submit button is enabled
   * @param index - number - this is the index of the input field.
   * @param event - the input event
   */
  onPinEntry(index: number, event: any): void {
    /**
     * STEPS:
     * delete all non-number characters from the input and
     * assign the input event values to correct PIN inputs,
     * then checking the length of the PIN inputs for exactly 8,
     * then checking if the PIN inputs are equal
     */
    this.deleteNonNumber(index, event);
    this.submitBtnDisabled();
    this.lengthWarning();
    this.matchWarning();
  }

  /**
   * Enable the submit button if the PIN inputs:
   *    are equal, not null, length must be 8,
   *    string must be a number
   */
  submitBtnDisabled(): void {
    this.submitDisabled = !(
      this.control1.value === this.control2.value &&
      this.control1.value.length === 8 &&
      this.control2.value.length === 8 &&
      this.isNumber(this.control1.value) &&
      this.isNumber(this.control2.value)
    );
  }

  /**
   * Checking the length of the PIN inputs for exactly 8:
   * sets excessive variable to true if the length is greater than 8
   * sets insufficient variable to true if the length is less than 8
   */
  lengthWarning(): void {
    this.excessive =
      8 < this.control1.value.length || 8 < this.control2.value.length;
    this.insufficient =
      (8 > this.control1.value.length && this.control1.value.length > 0) ||
      (8 > this.control2.value.length && this.control2.value.length > 0);
  }

  /* Checking if the PIN inputs are equal */
  matchWarning(): void {
    if (this.control1.value.length > 0 && this.control2.value.length > 0) {
      this.non_match = this.control1.value !== this.control2.value;
    } else {
      this.non_match = false;
    }
  }

  /* Helper function to test for valid numbers */
  isNumber(str: string | any): boolean {
    if (typeof str !== 'string') {
      return false;
    } else if (str.trim() === '') {
      return false;
    }
    return !Number.isNaN(Number(str));
  }

  /**
   * Discard all non-numbers
   * Add the input event values to correct PIN inputs
   */
  deleteNonNumber(index: number, event: any): void {
    // event type is 'any' because some issue with Input and InputEvent not being compatible
    if (index === 1) {
      this.PIN1 = Array.from(this.control1.value);
      this.PIN1.forEach((element, i) => {
        if (!this.isNumber(element)) {
          this.PIN1.splice(i, 1);
        }
      });
      this.control1.setValue(this.PIN1.join(''));
    } else if (index === 2) {
      this.PIN2 = Array.from(this.control2.value);
      this.PIN2.forEach((element, i) => {
        if (!this.isNumber(element)) {
          this.PIN2.splice(i, 1);
        }
      });
      this.control2.setValue(this.PIN2.join(''));
    }
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store.select(returnText(id)).subscribe(val => {
      msg = val;
    }).unsubscribe();
    return msg;
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  storeTextFetch(id: string, store: Store): string {
    let msg = '';
    store.select(returnText(id)).subscribe(val => {
      msg = val;
    }).unsubscribe();
    return msg;
  }

}
