import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { returnCancelStep, returnRedirectDuration, returnText, returnToken, } from 'src/state/selectors';
import { MainComponent } from 'src/app/pages/main/main.component';
import { setCancelStep, setOrderState } from 'src/state/actions';
import { countdown, extendPollingTimeOut, OrderState, Toasts, ToastType, tokenRequests, } from 'src/helperFunctions';
import { SiToastService } from '@simpl/siemens-brand-ng/toast';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cancel-service',
  templateUrl: './cancel-service.component.html',
  styleUrls: ['./cancel-service.component.scss'],
})
export class CancelServiceComponent implements OnInit {
  constructor(
    private store: Store,
    private main: MainComponent,
    private toastService: SiToastService
  ) {}  

  // makes the OrderState enum available in the template
  OrderState = OrderState;
  cancelStep = 1;
  confirmation = false;
  time = {
    countDownLength: 1,
    count: 1,
  };

  ngOnInit(): void {
    this.store.select(returnCancelStep)
      .subscribe((val) => {
        this.cancelStep = val;
      });
    this.store.select(returnRedirectDuration('cancel')) 
      .subscribe((val) => {
        this.time.countDownLength = val;
      }).unsubscribe();
  }

  cancel() {
    extendPollingTimeOut(this.store);
    this.cancelToken(this.toastService, this.storeTextFetch);
    this.store.dispatch(setOrderState({ value: OrderState.PROCESSING }));
    this.store.dispatch(setCancelStep({ value: 2 }));
    countdown(this.time, this.redirect.bind(this));
  }

  redirect(): void {
    this.main.selectTab(0);
    this.confirmation = !this.confirmation;
    this.store.dispatch(setCancelStep({ value: 1 }));
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store
      .select(returnText(id))
      .subscribe((val) => {
        msg = val;
      })
      .unsubscribe();
    return msg;
  }

  /**
   * fetches a language specific message from the store
   * Store issue fix
   * @param id
   * @returns msg
   */
  storeTextFetch(id: string, store: Store): string {
    let msg = '';
    store
      .select(returnText(id))
      .subscribe((val) => {
        msg = val;
      })
      .unsubscribe();
    return msg;
  }

  /**
   * The `cancelToken` function is an asynchronous function that cancels a token by making a DELETE
   * request to a backend API and handles the success and error cases by displaying toast messages.
   * @param {SiToastService} toastService - SiToastService is a service used for displaying toast
   * messages in the application.
   * @param storeTextFetch - The `storeTextFetch` parameter is a function that takes an `id` (string) and
   * a `store` object as arguments and returns a string. It is used to fetch text from the store based on
   * the provided `id`.
   */
  async cancelToken(
    toastService: SiToastService,
    storeTextFetch: (id: string, store: Store) => string
  ): Promise<void> {
    const _cancelToken = this.store
      .select(returnToken)
      .subscribe(async (token) => {
        await axios({
          method: 'delete',
          url: `${environment.NG_APP_BACKEND}/token/${token}`,
          withCredentials: true,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
          .then(() => {
            _cancelToken.unsubscribe();
            tokenRequests(this.store, toastService, storeTextFetch);
            Toasts(
              toastService,
              ToastType.SUCCESS,
              storeTextFetch('Cancel_confirmed', this.store),
              5000
            );
          })
          .catch((error) => {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            Toasts(
              toastService,
              ToastType.ERROR,
              storeTextFetch('Token_Process_Error', this.store),
              5000
            );
            console.error(`Cancel Token Error: ${JSON.stringify(error)}`);
          });
      });
  }
}
