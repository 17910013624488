import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { returnText } from 'src/state/selectors';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(private store: Store) {}
  
  version = environment.version;

  ngOnInit(): void {
    return;
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store.select(returnText(id)).subscribe(val => {
      msg = val;
    }).unsubscribe();
    return msg;
  }
}
