<section>
  <!--style="overflow-x: hidden; overflow-y: auto;" -->
  <div class="row">
    <!-- Graphics -->
    <div class="col-4" style="position: relative; top: 40px">
      <div class="bg-vrt-line"></div>
      <div
        class="icon-box"
        style="top: -45px"
        [class.inactive]="activateStep !== 1"
      >
        <i class="siemens-acknowledge-events"></i>
        <div>{{ textFetch("Activation_confirm") }}</div>
      </div>
      <div
        class="icon-box"
        style="top: 134px"
        [class.inactive]="activateStep !== 2"
      >
        <i class="siemens-success"></i>
        <div>{{ textFetch("Activation_active") }}</div>
      </div>
    </div>

    <!-- Text Fields -->
    <div class="col-8">
      <!-- Confirm Activation Section -->
      <div *ngIf="activateStep === 1">
        <div style="margin: 25px 0">
          <span style="color: #00ffb9">{{ textFetch("Step") }} 1/2</span>
          <h6>{{ textFetch("Activation_confirm") }}</h6>
        </div>
        <div style="margin: 25px 0">
          {{ textFetch("Activation_main") }}
        </div>
        <button type="button" class="btn btn-hero" (click)="confirm(PinInput)">
          {{ textFetch("Activation_button") }}
        </button>
      </div>

      <!-- Activation Processing -->
      <div *ngIf="activateStep === 2">
        <div style="margin: 25px 0">
          <span style="color: #00ffb9">{{ textFetch("Step") }} 2/2</span>
          <h6>{{ textFetch("Activation_active") }}</h6>
        </div>
        <div style="margin: 25px 0">
          {{ textFetch("Activation_confirmation") }} {{ textFetch("Activation_support") }}
<!--          <a-->
<!--            href="https://wiki.siemens.com/display/SiemensCA/Virtual+Smart+Card"-->
<!--            class="fakeLink"-->
<!--            target="_blank"-->
<!--            style="text-decoration: underline"-->
<!--          >-->
<!--            {{ textFetch("Support") }}-->
<!--          </a>-->
        </div>
        <div style="margin: 25px 0">
           {{ textFetch("Redirecting") }} {{ time.count }} {{ textFetch("Seconds") }}
        </div>
      </div>
    </div>
    <!-- PIN ACTIVATION MODAL -->
    <ng-template #PinInput>
      <div class="modal-header">
        <h4 class="modal-title">{{ textFetch("Token_PIN_Input") }}</h4>
      </div>
      <div class="modal-body">
        <si-form-group [label]='textFetch("Enter_PIN")'>
          <input
            [formControl]="control1"
            siFormControl
            style="margin: 25px 0"
            type="password"
            maxlength="8"
            (input)="onPinEntry(1, $event)"
          />
          <input
            [formControl]="control2"
            siFormControl
            style="margin: 25px 0"
            type="password"
            maxlength="8"
            (input)="onPinEntry(2, $event)"
          />
        </si-form-group>
        <div class="err" *ngIf="insufficient">
          {{ textFetch('Insufficient_Length') }}
        </div>
        <div class="err" *ngIf="excessive">
          {{ textFetch('Excessive_Length') }}
        </div>
        <div class="err" *ngIf="non_match">
          {{ textFetch('Input_Mismatch') }}
        </div>
      </div>
      <div class="modal-footer" style="padding: 15px 5%; float: left;">
        <button
          type="button"
          class="btn btn-hero btn-width"
          (click)="submit()"
          [disabled]="submitDisabled"
        >
          {{ textFetch('Submit_Activate') }}
        </button>
        <button
          class="btn btn-danger btn-width"
          (click)="cancel()"
          style="margin-left: 25px"
        >
          {{ textFetch('Cancel_Activate') }}
        </button>
      </div>
    </ng-template>
  </div>
</section>
