import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { returnOrderStep, returnRedirectDuration, returnUserCompany, returnText } from 'src/state/selectors';
import { MainComponent } from 'src/app/pages/main/main.component';
import { setOrderState, setOrderStep } from 'src/state/actions';
import { countdown, extendPollingTimeOut, OrderState, Toasts, ToastType, } from 'src/helperFunctions';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { SiToastService } from '@simpl/siemens-brand-ng/toast';
import { UserPrice } from 'src/models/user-price.model';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  constructor(
    private store: Store,
    private main: MainComponent,
    private toastService: SiToastService
  ) { }

  // makes the OrderState enum available in the template
  OrderState = OrderState;
  orderStep = 1;
  order = false;
  time = {
    countDownLength: 1,
    count: 1,
  };
  userPrices: UserPrice = JSON.parse(environment.NG_APP_USER_PRICES);
  userCompany = '';


  ngOnInit(): void {
    this.store.select(returnOrderStep).subscribe((val) => {
      this.orderStep = val;
    });

    this.store.select(returnRedirectDuration('order')).subscribe((val) => {
      this.time.countDownLength = val;
    }).unsubscribe();

    this.store.select(returnUserCompany).subscribe((val) => {
      this.userCompany = val;
    }).unsubscribe();
  }

  ordering(): void {
    extendPollingTimeOut(this.store);
    this.orderToken();
    this.store.dispatch(setOrderStep({ value: 2 }));
    this.store.dispatch(setOrderState({ value: OrderState.PROCESSING }));
    countdown(this.time, this.redirect.bind(this));
  }

  // Followup action for the countdown timer
  redirect(): void {
    this.main.selectTab(0);
    this.order = !this.order;
    this.store.dispatch(setOrderStep({ value: 1 }));
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store
      .select(returnText(id))
      .subscribe((val) => {
        msg = val;
      })
      .unsubscribe();
    return msg;
  }

  /**
   * POST request to the backend for a new token
   * Sets the token in store
   */
  async orderToken(): Promise<void> {
    await axios({
      method: 'post',
      url: `${environment.NG_APP_BACKEND}/token`,
      withCredentials: true,
      data: 'dummy-needed-by-tomcat-cors-filter',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then(() => {
        // handle success
        Toasts(
          this.toastService,
          ToastType.SUCCESS,
          this.textFetch('Order_processing'),
          5000
        );
      })
      .catch((error) => {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        Toasts(
          this.toastService,
          ToastType.ERROR,
          this.textFetch('Token_Process_Error'),
          5000
        );
        console.error('Token Order error: ');
        console.error(JSON.stringify(error.toJSON()));
      });
  }

  //todo: when the backend provides correct company, fix this!
  price(): string {
    return `${this.textFetch("Order_costs_1")}`;
  }

  // }  price(): string {
  //   if (this.userCompany in this.userPrices) {
  //     // company name and price
  //      return `${this.userCompany}: ${this.userPrices[this.userCompany]} ${this.textFetch("Order_costs_1")}`;
  //   } else {
  //     // unrecognized company name
  //     return `${this.textFetch("Tenant_Unknown")}`;
  //   }
  // }

}
