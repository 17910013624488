<section class="container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col col-md-10" style="margin-top: 75px">
      <!-- Normal logout -->
      <div class="logout-box">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ textFetch("Logout_heading") }}</h5>
            <p class="card-text">
              {{ textFetch("Logout_main") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
