import { A11yModule } from '@angular/cdk/a11y';
import { ActivateComponent } from './subcomponents/activate/activate.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CancelServiceComponent } from './subcomponents/cancel-service/cancel-service.component';
import { CompromisedComponent } from './subcomponents/compromised/compromised.component';
import { environment } from '../environments/environment';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { LandingComponent } from './pages/landing/landing.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { MainComponent } from './pages/main/main.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { OrderComponent } from './subcomponents/order/order.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ReactiveFormsModule } from '@angular/forms';
import { Reducer } from 'src/state/reducers';
import { SI_VALIDATION_ERROR_RESOLVER_MAP, SiFormGroupModule } from '@simpl/siemens-brand-ng/form-group';
import { SiBreadcrumbModule } from '@simpl/siemens-brand-ng/breadcrumb';
import { SiFooterModule } from '@simpl/siemens-brand-ng/footer'; // @simpl/siemens-brand-ng/footer
import { SiHeaderModule } from '@simpl/siemens-brand-ng/header';
import { SiLoadingSpinnerModule } from '@simpl/siemens-brand-ng/loading';
import { SiLocalizationNgModule } from '@simpl/siemens-brand-localize-with-angular';
import { SiToastModule } from '@simpl/siemens-brand-ng/toast';
import { StartComponent } from './subcomponents/start/start.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { TabsModule as NgxTabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule as NgxTooltipModule } from 'ngx-bootstrap/tooltip';
import { RenewComponent } from './subcomponents/renew/renew.component';

@NgModule({
  declarations: [
    ActivateComponent,
    AppComponent,
    CancelServiceComponent,
    CompromisedComponent,
    FooterComponent,
    HeaderComponent,
    LandingComponent,
    LogoutComponent,
    MainComponent,
    OrderComponent,
    StartComponent,
    RenewComponent,
  ],
  imports: [
    A11yModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
    NgxTabsModule.forRoot(),
    PopoverModule.forRoot(),
    ReactiveFormsModule,
    SiBreadcrumbModule,
    SiFooterModule,
    SiFormGroupModule,
    SiHeaderModule,
    SiLoadingSpinnerModule,
    SiLocalizationNgModule,
    SiToastModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreModule.forRoot({ storeEntries: Reducer }),
    NgxTooltipModule.forRoot(),
  ],
  providers: [
    {
      provide: SI_VALIDATION_ERROR_RESOLVER_MAP,
      useValue: { required: 'Value is required' }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
