import { environment } from './environments/environment';
import { returnAuthDone, returnPollingTimeOut, returnPollingTimeOutDuration } from './state/selectors';
import { setAuthDone, setDeactivate, setLanguage, setLanguageIcon, setOrderState, setPageExpired, setPollingTimeOut, setToken, setTokenRequest } from './state/actions';
import { SiToastService, SiToastTypes } from '@simpl/siemens-brand-ng/toast';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import axios from 'axios';
import { Router } from '@angular/router';

export enum ToastType {
  SUCCESS,
  INFO,
  WARNING,
  ERROR,
}

export enum OrderState {
  ORDER,
  ACTIVATE,
  CANCEL,
  PROCESSING,
  RENEW,
  ERROR,
}

/**
 * The `countdown` function takes a model object with a count and countDownLength property, starts a
 * countdown timer, and executes followupActions when the countdown reaches zero.
 * @param model - The `model` parameter is an object that contains two properties:
 * @param followupActions - The `followupActions` parameter is a function that will be called once the
 * countdown reaches zero. It is used to specify any actions that should be performed after the
 * countdown is complete.
 */
export function countdown(
  model: { count: number; countDownLength: number },
  followupActions: () => void
): void {
  model.count = model.countDownLength;
  const timer = setInterval(() => {
    model.count--;
    if (model.count === 0) {
      clearInterval(timer);
      model.count = model.countDownLength;
      followupActions();
    }
  }, 1000);
}

/**
 * The `Toasts` function takes in a toast service, toast type, message, and time and displays a toast
 * message based on the provided parameters.
 * @param {SiToastService} toastService - The `toastService` parameter is an instance of a service that
 * is responsible for displaying toast messages. It is used to call the `showToast` method to display
 * the toast message.
 * @param {ToastType} type - The `type` parameter is an enum that represents the type of toast message.
 * It can have one of the following values: `SUCCESS`, `INFO`, `WARNING`, or `ERROR`.
 * @param {string} msg - The `msg` parameter is a string that represents the message content of the
 * toast. It is the text that will be displayed in the toast notification.
 * @param {number} time - The `time` parameter is the duration in milliseconds for which the toast
 * message should be displayed.
 */
export function Toasts(
  toastService: SiToastService,
  type: ToastType,
  msg: string,
  time: number
): void {
  switch (type) {
    case ToastType.SUCCESS:
      toastService.showToast({
        content: msg,
        type: SiToastTypes.SUCCESS,
        timeout: time,
      });
      break;
    case ToastType.INFO:
      toastService.showToast({
        content: msg,
        type: SiToastTypes.INFO,
        timeout: time,
      });
      break;
    case ToastType.WARNING:
      toastService.showToast({
        content: msg,
        type: SiToastTypes.WARNING,
        timeout: time,
      });
      break;
    case ToastType.ERROR:
      toastService.showToast({
        content: msg,
        type: SiToastTypes.DANGER,
        timeout: time,
      });
      break;
    default:
      break;
  }
}

/**
 * The `polling` function is an asynchronous function that repeatedly makes token requests at a
 * specified interval, as long as
 *    the user is authenticated,
 *    the maximum date (store.pollingTimeOut) hasn't been exceeded, checked by continuePolling(),
 *    and the document is visible.
 * @param {Store} store - The store is an object that holds the application state. It is typically used
 * in a Redux or NgRx application to manage the state of the application and handle actions and
 * reducers.
 * @param {number} duration - The `duration` parameter is the time interval (in milliseconds) between
 * each polling request. It determines how often the polling function will be called.
 * @param {SiToastService} toastService - The `toastService` parameter is an instance of a service that
 * is responsible for displaying toast notifications to the user. It is used to show a warning toast
 * when the polling is stopped.
 * @param storeTextFetch - The `storeTextFetch` parameter is a function that takes an `id` (presumably
 * a string) and a `store` object as arguments and returns a string. It is used to fetch text from the
 * store based on the provided `id`.
 * @returns Promise
 */
export async function polling(
  store: Store,
  duration: number,
  toastService: SiToastService,
  router: Router,
  storeTextFetch: (id: string, store: Store) => string
): Promise<void> {
  let authDone: boolean;
  const subscription: Subscription = store
    .select(returnAuthDone)
    .subscribe((_authDone) => {
      authDone = _authDone;
    });
  sleep(250).then(async () => {
    if (authDone === true && continuePolling(store)) {
      await tokenRequests(store, toastService, storeTextFetch);
      await new Promise((resolve) => {
        setTimeout(resolve, duration);
      });
      polling(store, duration, toastService, router, storeTextFetch);
    } else {
      // stops the automatic firing of polling, if authDone is changed, i.e. sign-out
      subscription.unsubscribe();
      store.dispatch(setDeactivate({value: true})); // sets the page deactivation grey-out
      store.dispatch(setPageExpired({ value: true })); // shows expired toast
      store.dispatch(setAuthDone({ value: false })); // sets the login/logout button, and forces an auth check
    }
  });
}

/**
 * GET request to the backend for EXISTING TOKEN REQUESTS
 * changes visibility of components and notification contents
 * @param store
 * @returns Promise
 */
export async function tokenRequests(
  store: Store,
  toastService: SiToastService,
  storeTextFetch: (id: string, store: Store) => string
): Promise<any> {
  await axios({
    method: 'get',
    url: `${environment.NG_APP_BACKEND}/token`,
    withCredentials: true,
  })
    .then((res) => {
      // Set the tokenRequest and token values
      store.dispatch(setTokenRequest({ value: res.data }));
      // existing requests or tokens, it's not an empty array
      if (res.data.length > 0) {
        store.dispatch(setToken({ value: res.data[0].tokenId }));
      }
      return res.data;
    })
    .then((data) => {
      /**
       * set the OrderStates according to the tokenRequest contents
       * Switch statement had issues (with the data.length > 0 condition) ?!
       */
      if (data.length === 0) {
        store.dispatch(setOrderState({ value: OrderState.ORDER }));
      }
      if (data.length > 0 && data[0]['errorDetails'] === null) {
        if (data[0]['state'] === 'WAIT_FOR_ACTIVATION') {
          store.dispatch(setOrderState({ value: OrderState.ACTIVATE }));
        } else if (data[0]['state'] === 'PROCESSED') {
          store.dispatch(setOrderState({ value: OrderState.CANCEL }));
        }
      }
      if (data.length > 0 && data[0]['errorDetails'] != null) {
        console.error('Token fetching errors: ', data[0]['errorDetails']);
        Toasts(
          toastService,
          ToastType.ERROR,
          storeTextFetch('Token_Process_Error', store),
          5000
        );
        store.dispatch(setOrderState({ value: OrderState.ERROR }));
      }
    })
    .catch((error: Object) => {
      if (Object.keys(error).length != 0) {
        console.error('Error in tokenRequests: ', JSON.stringify(error));
        // @ts-expect-error HTTP 403 from TokenRA: Token is not valid anymore
        if (error.response?.status === 403) {
          store.dispatch(setAuthDone({ value: false }));
          Toasts(
            toastService,
            ToastType.WARNING,
            storeTextFetch('Token_Expiration', store),
            5000
          );
          store.dispatch(setOrderState({ value: OrderState.ERROR }));
        } else {
          // show generic error message and log error to console
          Toasts(
            toastService,
            ToastType.ERROR,
            storeTextFetch('Token_Process_Error', store),
            5000
          );
          store.dispatch(setOrderState({ value: OrderState.ERROR }));
        }
      } else {
        // show generic error message and log error to console
        Toasts(
          toastService,
          ToastType.ERROR,
          storeTextFetch('Token_Process_Error', store),
          5000
        );
      }
      store.dispatch(setOrderState({ value: OrderState.ERROR }));
    });
}

/**
 * The function checks if a language preference is stored in the browser's local storage and dispatches
 * actions to set the language and language icon in the store accordingly.
 * @param {Store} store - The `store` parameter is an object that represents the Redux store. It is
 * used to dispatch actions and manage the application state.
 */
export function languagePreference(store: Store): void {
  if (localStorage.getItem('language') != null) {
    const lang = localStorage.getItem('language');
    // @ts-ignore: null check is done above
    store.dispatch(setLanguage({ setting: lang }));
    // @ts-ignore: null check is done above
    store.dispatch(setLanguageIcon({ value: lang }));
  }
}

/**
 * sleeping function to force synchronicity
 * @param milliseconds
 * @returns Promise
 */
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

/**
 * The function `continuePolling` checks if the polling time has expired for a given store.
 * @param {Store} store - The `store` parameter is an object that represents the state of the
 * application. It typically contains data and provides methods to update and retrieve the data.
 * @returns a boolean value, which indicates whether the polling has expired or not.
 */
function continuePolling(store: Store): boolean {
  let _continue = false;
  store
    .select(returnPollingTimeOut)
    .subscribe((_time) => {
      Date.now() < _time ? (_continue = true) : (_continue = false);
    })
    .unsubscribe();
  return _continue;
}

/**
 * The function extends the polling timeout for a store by dispatching an action to update the timeout
 * value.
 * @param {Store} store - The `store` parameter is an object that represents the Redux store. It is
 * used to dispatch actions and manage the application state.
 */
export function extendPollingTimeOut(store: Store): void {
  store
    .select(returnPollingTimeOutDuration)
    .subscribe((_duration) => {
      store.dispatch(setPollingTimeOut({ value: _duration + Date.now() }));
    })
    .unsubscribe();
}
