<footer class="si-footer si-footer-legal-only">
  <div class="container">
    <!-- copyright + version -->
    <span class="switcher">
      <span class="copyright"><si-copyright></si-copyright></span>
      <span class="version">Version: {{ version }}</span>
    </span>
    <span>
        <a href="https://www.siemens.com/corporate-information" target="_blank" class="footer_links spacing">{{ textFetch('Footer_Corporate_Information') }}</a>
        <a href="https://www.siemens.com/privacy-notice" target="_blank" class="footer_links spacing">{{ textFetch('Footer_Privacy_Notice') }}</a>
        <a href="https://www.siemens.com/cookie-notice" target="_blank" class="footer_links spacing">{{ textFetch('Footer_Cookie_Notice') }}</a>
        <a href="https://www.siemens.com/terms-of-use" target="_blank" class="footer_links spacing">{{ textFetch('Footer_Terms_of_Use') }}</a>
        <a href="https://www.siemens.com/digital-id" target="_blank" class="footer_links spacing">{{ textFetch('Footer_Digital_ID') }}</a>
    </span> 
  </div>
</footer>
