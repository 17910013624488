import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { siRouterFadeAnimation } from '@simpl/siemens-brand-ng/tools';
import { languagePreference } from 'src/helperFunctions';
import { returnDeactivate } from 'src/state/selectors';
import { ElementRef } from '@angular/core';

@Component({
  selector: '<app-root>',
  templateUrl: './app.component.html',
  animations: [siRouterFadeAnimation],
})
export class AppComponent implements OnInit {
  constructor(private store: Store, private _elementRef: ElementRef) {
    languagePreference(this.store);
  }
  
  greyOut: string = '';

  ngOnInit(): void {
    // show/hide the greyed out foreground
    this.store.select(returnDeactivate).subscribe((deactivated: boolean) => {
      if (deactivated) {
        this.greyOut =
          'opacity: 0.33; pointer-events: none; z-index: 1; position: relative;'; 
        } else { this.greyOut =
          'opacity: 1; pointer-events: all; z-index: 1; position: relative;';
      }
    });
    this._elementRef.nativeElement.removeAttribute("ng-version");
  }

  getRouteAnimation(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : null;
  }
}
