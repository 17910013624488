import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { returnText } from 'src/state/selectors';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {
    return;
  }

  /**
   * fetches a language specific message from the store
   * @param id
   * @returns msg
   */
  textFetch(id: string): string {
    let msg = '';
    this.store.select(returnText(id)).subscribe(val => {
      msg = val;
    }).unsubscribe();
    return msg;
  }

}
